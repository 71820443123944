<template>
  <div class="widget">
    <h4>Результаты конкурса</h4>
    <div v-for="team in teamsData" :key="team.id" class="team">
      <div class="team__name">
        <h4>{{ team.name }}</h4>
        <div style="font-size: 12px">{{ team.members.slice(0, 3).map(el => el.name).join(', ') }}</div>
      </div>
      <div class="team__points">
        {{ team.points }}
      </div>
    </div>
    <a v-if="count < 1000" @click="count = 1000">Показать все</a>
  </div>
</template>

<script>
export default {
  name: "LeadersBoardWidget",
  props: ['teams'],
  data(){
    return {
      count: 10
    }
  },
  computed: {
    event() {
      return this.$store.state.events.currentEvent;
    },
    teamsData() {
      let teams = this.teams.slice();
      teams.sort((el, el2) => el.points > el2.points ? -1 : el.points === el2.points ? 0 : 1)
      teams = teams.filter(el => !el.is_hide)
      return teams.slice(0, this.count);
    },
  },
}
</script>

<style scoped>
.widget {
  padding: 20px;
  border-radius: 20px;
  background: white;
  box-shadow: rgba(20, 28, 37, 0.1) 0px 1px 10px;
}

.team {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
}

.team__points {
  padding: 20px;
  background: #c6e7f6;
  width: 2em;
  height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
</style>
